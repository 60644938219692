import React from 'react'

import Page from '../../components/page'
import { WarningNote } from '../../components/highlight'
import withRoot from '../../withRoot'

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  section: {
    marginTop: theme.spacing(4),
    color: theme.palette.secondary.main,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  premium: {
    color: theme.palette.primary.light,
    fontWeight: 'bold'
  },
})

@withRoot
@withStyles(styles)
export default class Money extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Transaction Fees & Payouts">
        <Typography>
          If you publish a paid design, users will have to pay your chosen price to be able to use it.<br />
          The purchase is linked with their account, if they switch designs or uninstall our app, they will be able to reinstall it without problem.
        </Typography>

        <Typography variant="h6" className={classes.section}>Fees</Typography>
        <Typography>
          We take some fees on every transactions. They are described below:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">REGULAR DESIGNER</TableCell>
              <TableCell className={classes.premium} align="center">PREMIUM DESIGNER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center"><b>35%</b> of the amount of the transaction (minimum of <b>$0.35 USD</b>)</TableCell>
              <TableCell align="center"><b>30%</b> of the amount of the transaction (minimum of <b>$0.30 USD</b>)</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="h6" className={classes.section}>Payouts</Typography>
        <Typography>
          Payouts are done only via PayPal. No other solution is available at the moment.<br />
          You will be paid out every 1st of the month through your PayPal account if the current balance is above <b>$30 USD</b>. <br />
          You can access your current balance and the total amount paid in the <a className={classes.link} href='https://fitface.xyz/user/account' target="_blank" rel="noopener noreferrer">Settings</a>
        </Typography>
        <WarningNote>
          Make sure your paypal account can receive payment.
          Paypal does not support receiving money in some countries.
          In that case, we won&apos;t be able to send your payouts.<br />
          Check if your country can receive paypal payment <a className={classes.link} href='https://www.paypal.com/us/cgi-bin/webscr?cmd=_display-country-functionality-outside'>here</a>
        </WarningNote>
      </Page>
    )
  }
}
